import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { sendHttpReq } from "Src/utils";
import { Button, CircularProgress, Typography } from "@mui/material";
import { CategorySubPageForm } from "Pages/Products/CategorySubPages/CategorySubPageForm/CategorySubPageForm";
import { showEventSnack } from "Redux/slices/appSlice";
import { useAppDispatch } from "Redux/hooks";
export const CategorySubPageEdit = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [categorySubPage, setCategorySubPage] = useState(null);
    const [categorySubPageEditing, setCategorySubPageEditing] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const title = categorySubPage ? categorySubPage.title + " - Редактирование страницы" : "Редактирование страницы";
        document.title = `${title} | LS.NET админка`;
    }, [categorySubPage]);
    const loadData = () => {
        setIsLoading(true);
        sendHttpReq({
            method: "get",
            url: "/category-sub-page/" + id,
        }).then(res => {
            setCategorySubPage(res.data);
            setCategorySubPageEditing(Object.assign({}, res.data));
        }).finally(() => {
            setIsLoading(false);
        });
    };
    useEffect(() => {
        loadData();
    }, []);
    const update = (categorySubPageEditing) => {
        var _a, _b, _c, _d;
        sendHttpReq({
            method: "post",
            url: "/category-sub-page/update",
            data: {
                id: categorySubPageEditing.id,
                category_id: ((_a = categorySubPageEditing.category) === null || _a === void 0 ? void 0 : _a.id) || null,
                url: categorySubPageEditing.url,
                title: categorySubPageEditing.title,
                meta_title: categorySubPageEditing.metaTitle,
                meta_keywords: categorySubPageEditing.metaKeywords,
                meta_description: categorySubPageEditing.metaDescription,
                text: categorySubPageEditing.text,
                sex: categorySubPageEditing.sex,
                brand_id: ((_b = categorySubPageEditing.brand) === null || _b === void 0 ? void 0 : _b.id) || null,
                color_id: ((_c = categorySubPageEditing.color) === null || _c === void 0 ? void 0 : _c.id) || null,
                material_id: ((_d = categorySubPageEditing.material) === null || _d === void 0 ? void 0 : _d.id) || null,
                season: categorySubPageEditing.season,
                size: categorySubPageEditing.size,
                noindex: categorySubPageEditing.noindex,
            },
        }).then(res => {
            setCategorySubPage(res.data);
            setCategorySubPageEditing(Object.assign({}, res.data));
            dispatch(showEventSnack({ isShowed: true, type: "success", messageValue: "Сохранено" }));
        });
    };
    if (isLoading) {
        return React.createElement(CircularProgress, { value: 10, className: 'defaultLoader' });
    }
    if (!categorySubPage) {
        return (React.createElement(Typography, { sx: { mt: "5rem" }, align: 'center' },
            "\u0421\u0442\u0440\u0430\u043D\u0438\u0446\u0430 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u0430. ",
            React.createElement("br", null),
            React.createElement(Link, { to: "/products/categorySubPages" }, "\u041A \u0441\u043F\u0438\u0441\u043A\u0443 \u0441\u0442\u0440\u0430\u043D\u0438\u0446")));
    }
    return React.createElement("div", null,
        React.createElement("div", { style: { marginBottom: "10px" } },
            React.createElement(Link, { to: "/products/categorySubPages" },
                React.createElement(Button, { size: 'small' }, "\u041A \u0441\u043F\u0438\u0441\u043A\u0443 \u0441\u0442\u0440\u0430\u043D\u0438\u0446"))),
        categorySubPageEditing && (React.createElement(CategorySubPageForm, { categorySubPage: categorySubPageEditing, onChange: (categorySubPageFull) => setCategorySubPageEditing(categorySubPageFull), onSubmit: (categorySubPageEditing) => update(categorySubPageEditing) })));
};
