import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from "@mui/material";
import React from "react";
import { brown } from "@mui/material/colors";
import { OrdersCourierDeliveriesTableRow } from "../OrdersCourierDeliveryTableRow";
const tableHeaders = [
    { name: "№" },
    { name: "ФИО инициатора" },
    { name: "Номер телефона инициатора" },
    { name: "Компания" },
    { name: "Город" },
    { name: "Стоимость для заказчика" },
    { name: "Дата" },
    { name: "Откуда" },
    { name: "Куда" },
    { name: "Курьер" },
    { name: "Статус поручений" },
    { name: "Оплата" },
    { name: "Комментарий" },
    { name: "Заказ" },
    { name: "Действия" },
];
const OrdersCourierDeliveriesTable = ({ isLoading, deliveries, couriers, onUpdate, onDelete, companiesData, }) => {
    if (!isLoading && deliveries.length > 0) {
        return (React.createElement(TableContainer, { component: Paper, sx: { mt: "1rem" } },
            React.createElement(Table, null,
                React.createElement(TableHead, { sx: { background: brown[500] } },
                    React.createElement(TableRow, null, tableHeaders.map((tableHeader, index) => {
                        const { name } = tableHeader;
                        return (React.createElement(TableCell, { key: index, sx: { color: "white" }, align: 'center' }, name));
                    }))),
                React.createElement(TableBody, null, deliveries.map((delivery) => (React.createElement(OrdersCourierDeliveriesTableRow, { key: delivery.id, delivery: delivery, couriers: couriers, onUpdate: onUpdate, onDelete: () => onDelete(delivery), companiesData: companiesData })))))));
    }
    if (isLoading && deliveries.length === 0) {
        return React.createElement(CircularProgress, { value: 10, className: 'defaultLoader' });
    }
    return (React.createElement(Typography, { sx: { mt: "5rem" }, align: 'center' }, "\u041F\u043E\u0440\u0443\u0447\u0435\u043D\u0438\u0439 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E. \u0418\u0437\u043C\u0435\u043D\u0438\u0442\u0435 \u0434\u0430\u0442\u0443 \u0438\u043B\u0438 \u0444\u0438\u043B\u044C\u0442\u0440\u044B."));
};
export { OrdersCourierDeliveriesTable };
