import { Home as HomeIcon, Pages as PagesIcon, CardGiftcard as CardGiftcardIcon, ShoppingCart as ShoppingCartIcon, Group as GroupIcon, AlternateEmail as AlternateEmailIcon, ReceiptLong as ReceiptLongIcon, Analytics as AnalyticsIcon, Settings as SettingsIcon, BarChart as BarChartIcon, AttachMoney as AttachMoneyIcon, } from "@mui/icons-material/";
import { oldAdminUrl } from "Src/variables";
const LINKS = [
    { id: 1, Icon: HomeIcon, name: "главная", oldAdminUrl, permissions: [] },
    {
        id: 2,
        Icon: PagesIcon,
        name: "страницы",
        oldAdminUrl: `${oldAdminUrl}?section=Sections`,
        permissions: ["adminSections"],
    },
    {
        id: 3,
        Icon: CardGiftcardIcon,
        name: "товары",
        oldAdminUrl: `${oldAdminUrl}?section=Storefront`,
        permissions: [
            "adminStorefront", "productPreviews", "adminCategories", "adminBrands", "adminGoods", "adminMaterials",
            "adminSets", "adminBanners", "saleBanner", "adminShops", "categorySubPages",
        ],
    },
    {
        id: 4,
        url: "/orders",
        Icon: ShoppingCartIcon,
        name: "заказы",
        oldAdminUrl: `${oldAdminUrl}?section=Orders`,
        permissions: ["adminOrders"],
    },
    {
        id: 5,
        Icon: GroupIcon,
        name: "пользователи",
        oldAdminUrl: `${oldAdminUrl}?section=Users`,
        permissions: ["adminUsers"],
    },
    {
        id: 6,
        Icon: AlternateEmailIcon,
        name: "рассылки",
        oldAdminUrl: `${oldAdminUrl}?section=EmailTemplates&email_template=1`,
        permissions: ["sendMailing"],
    },
    {
        id: 7,
        Icon: ReceiptLongIcon,
        name: "заявки",
        oldAdminUrl: `${oldAdminUrl}?section=Oneclick`,
        permissions: ["adminOneClick"],
    },
    {
        id: 8,
        Icon: AnalyticsIcon,
        name: "аналитика продаж",
        oldAdminUrl: `${oldAdminUrl}?section=Analytics`,
        permissions: ["adminAnalytics"],
    },
    {
        id: 9,
        Icon: SettingsIcon,
        name: "настройки",
        oldAdminUrl: `${oldAdminUrl}?section=Setup`,
        permissions: ["adminSetup"],
    },
    {
        id: 10,
        Icon: BarChartIcon,
        name: "сводки",
        oldAdminUrl: `${oldAdminUrl}?section=Statistics&brands=1`,
        permissions: ["statistic"],
    },
    {
        id: 11,
        url: "/marketing",
        Icon: AttachMoneyIcon,
        name: "маркетинг",
        oldAdminUrl: `${oldAdminUrl}?section=SaleBanner`,
        permissions: ["adminCoupons", "saleBanner", "adminBanners", "adminSaleSettings"],
    },
    {
        id: 12,
        url: "/certificates",
        Icon: AttachMoneyIcon,
        name: "сертификаты",
        oldAdminUrl: '',
        permissions: ["adminCertificates"],
    },
];
export { LINKS };
