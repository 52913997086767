import React from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import { Link } from "react-router-dom";
export const CategorySubPagesList = ({ categorySubPages, isLoading, remove }) => {
    if (isLoading && categorySubPages.length === 0) {
        return React.createElement(CircularProgress, { value: 10, className: 'defaultLoader' });
    }
    if (categorySubPages.length === 0) {
        return (React.createElement(Typography, { sx: { mt: "5rem" }, align: 'center' }, "\u0421\u0442\u0440\u0430\u043D\u0438\u0446 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E. \u0418\u0437\u043C\u0435\u043D\u0438\u0442\u0435 \u0434\u0430\u0442\u0443 \u0438\u043B\u0438 \u0444\u0438\u043B\u044C\u0442\u0440\u044B."));
    }
    return React.createElement("div", { className: 'categorySubPages' }, categorySubPages.map(categorySubPage => (React.createElement("div", { className: 'categorySubPage', key: categorySubPage.id },
        React.createElement("div", null,
            categorySubPage.title,
            React.createElement(Link, { to: "/products/categorySubPages/edit/" + categorySubPage.id, className: 'categorySubPage__edit-btn' }, "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C"),
            React.createElement(Button, { color: 'error', onClick: () => remove(categorySubPage) }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C")),
        React.createElement("a", { className: 'categorySubPage__link', href: categorySubPage.fullUrl }, categorySubPage.fullUrl),
        categorySubPage.noindex && React.createElement("p", { style: { color: "red" } }, "\u0421\u0442\u0440\u0430\u043D\u0438\u0446\u0430 \u0437\u0430\u043A\u0440\u044B\u0442\u0430 \u043E\u0442 \u0438\u043D\u0434\u0435\u043A\u0441\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u044F!"),
        React.createElement("div", { className: 'categorySubPage__info' },
            React.createElement("p", null,
                "\u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044F: ",
                categorySubPage.category ? categorySubPage.category.name : "нет"),
            categorySubPage.sex > 0 && (React.createElement("p", null,
                "\u041F\u043E\u043B: ",
                ["", "мужской", "женский"][categorySubPage.sex])),
            categorySubPage.brand && (React.createElement("p", null,
                "\u0411\u0440\u0435\u043D\u0434: ",
                categorySubPage.brand.name)),
            categorySubPage.color && (React.createElement("p", null,
                "\u0426\u0432\u0435\u0442: ",
                categorySubPage.color.name)),
            categorySubPage.material && (React.createElement("p", null,
                "\u041C\u0430\u0442\u0435\u0440\u0438\u0430\u043B: ",
                categorySubPage.material.name)),
            categorySubPage.season && (React.createElement("p", null,
                "\u0421\u0435\u0437\u043E\u043D: ",
                categorySubPage.season)),
            categorySubPage.size && (React.createElement("p", null,
                "\u0420\u0430\u0437\u043C\u0435\u0440: ",
                categorySubPage.size)))))));
};
