import React from "react";
import { useTitle } from "Src/hooks";
import { Outlet, useLocation } from "react-router-dom";
import { Tabs } from "@mui/material";
import { LinkTab } from "Src/components";
const menuLinks = [
    { href: `/settings/tabel`, name: "Руководители отделов" },
    { href: `/settings/tabel/companies`, name: "Компании" },
];
export const Tabel = () => {
    useTitle("Табель - настройки");
    const { pathname } = useLocation();
    const currentTabIndex = menuLinks.findIndex((menuLink) => pathname === menuLink.href);
    return React.createElement("div", null,
        React.createElement(Tabs, { sx: { mt: "-10px", mb: "30px" }, value: currentTabIndex, role: 'navigation', scrollButtons: "auto", variant: "scrollable" }, menuLinks.map((menuLink) => (React.createElement(LinkTab, { key: menuLink.href, label: menuLink.name, to: menuLink.href })))),
        React.createElement(Outlet, null));
};
