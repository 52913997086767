import React, { useEffect, useState } from "react";
import { sendHttpReq } from "Src/utils";
import { Autocomplete, TextField } from "@mui/material";
export const CategorySelect = ({ currentCategory, onChange }) => {
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        sendHttpReq({
            method: "get",
            url: "/categories/enabled",
        }).then(res => {
            setCategories(res.data);
        });
    }, []);
    if (!categories.length) {
        return React.createElement(React.Fragment, null);
    }
    return React.createElement(Autocomplete, { value: currentCategory || undefined, onChange: (e, value) => onChange(value), renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { variant: 'outlined', label: '\u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044F', required: true })), disableClearable: true, options: categories, getOptionLabel: (option) => (option === null || option === void 0 ? void 0 : option.name) || "(Не выбрано)", size: 'small', style: { width: "300px" }, isOptionEqualToValue: (option, value) => !!value && option.id === value.id });
};
