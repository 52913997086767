import React, { useEffect, useState } from "react";
import { sendHttpReq } from "Src/utils";
import { apiHost } from "Src/variables";
import { ChangeInput } from "Pages/Settings/Tabel/Companies/Components/ChangeInput";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { AddCompany } from "Pages/Settings/Tabel/Companies/Components/AddCompany";
import { CompanyDepartments } from "Pages/Settings/Tabel/Companies/CompanyDepartments";
export const Companies = () => {
    const [companies, setCompanies] = useState([]);
    const loadCompanies = () => {
        sendHttpReq({
            method: "get",
            url: apiHost + "/mrd/company",
        }).then(({ data }) => {
            setCompanies(data);
        });
    };
    useEffect(() => {
        loadCompanies();
    }, []);
    const renameCompany = (company, newName) => {
        sendHttpReq({
            method: "put",
            url: apiHost + "/mrd/company/" + company.id,
            data: {
                name: newName,
            },
        }).then(() => {
            loadCompanies();
        });
    };
    const removeCompany = (company) => {
        if (!confirm(`Удалить компанию ${company.name}?`)) {
            return;
        }
        sendHttpReq({
            method: "delete",
            url: apiHost + "/mrd/company/" + company.id,
        }).then(() => {
            loadCompanies();
        });
    };
    return React.createElement("div", null,
        companies.map(company => (React.createElement("div", { key: company.id, style: { lineHeight: 2, fontSize: "16px", padding: "15px", border: "1px #ccc solid", marginBottom: "7px" } },
            React.createElement("div", null,
                React.createElement(ChangeInput, { value: company.name, onSave: value => renameCompany(company, value) }),
                !company.departments.length && (React.createElement(IconButton, { style: { marginLeft: "10px" }, color: 'error', onClick: () => removeCompany(company) },
                    React.createElement(Delete, null)))),
            React.createElement(CompanyDepartments, { company: company, onChange: loadCompanies })))),
        React.createElement(AddCompany, { onSave: newCompany => {
                setCompanies([...companies, newCompany]);
            } }));
};
