import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { sendHttpReq } from "Src/utils";
export const CategorySubPageFilters = ({ updateFilters, subButtons }) => {
    const [urlQuery, setUrlQuery] = useState("");
    const [debouncedUrlQuery] = useDebounce(urlQuery, 400);
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        sendHttpReq({
            method: "get",
            url: "/categories/enabled",
        }).then(res => {
            setCategories(res.data);
        });
    }, []);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [sex, setSex] = useState(0);
    useEffect(() => {
        updateFilters({
            url: debouncedUrlQuery,
            categories: selectedCategories,
            sex: sex,
        });
    }, [debouncedUrlQuery, selectedCategories, sex]);
    return React.createElement("div", { style: { display: "flex", flexWrap: "wrap", gap: "15px" } },
        React.createElement(TextField, { label: '\u041F\u043E\u0438\u0441\u043A \u043F\u043E URL \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u044B', value: urlQuery, onChange: e => setUrlQuery(e.target.value), sx: { maxWidth: "500px", flexGrow: 1 }, size: 'small' }),
        React.createElement(Autocomplete, { value: selectedCategories || undefined, onChange: (e, value) => setSelectedCategories(value), renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { variant: 'outlined', label: '\u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044F', required: true })), disableClearable: true, options: categories, getOptionLabel: (option) => (option === null || option === void 0 ? void 0 : option.name) || "(Не выбрано)", size: 'small', style: { maxWidth: "500px", flexGrow: 1 }, isOptionEqualToValue: (option, value) => !!value && option.id === value.id, multiple: true }),
        React.createElement(FormControl, { variant: 'outlined', size: 'small', style: { width: "150px" } },
            React.createElement(InputLabel, null, "\u041F\u043E\u043B"),
            React.createElement(Select, { id: 'sex', name: 'sex', value: sex, label: '\u041F\u043E\u043B', size: 'small', onChange: (e) => setSex(parseInt(e.target.value)) },
                React.createElement(MenuItem, { value: 0 }, "\u041B\u044E\u0431\u043E\u0439"),
                React.createElement(MenuItem, { value: 1 }, "\u041C\u0443\u0436\u0441\u043A\u043E\u0439"),
                React.createElement(MenuItem, { value: 2 }, "\u0416\u0435\u043D\u0441\u043A\u0438\u0439"))),
        subButtons);
};
