import { useAppDispatch, useAppSelector } from "Redux/hooks";
import React, { useEffect } from "react";
import { showEventSnack } from "Redux/slices/appSlice";
import { Typography } from "@mui/material";
const ProtectedComponentWithPermission = ({ permission, children }) => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.userInfo);
    const isAllowed = user === null || user === void 0 ? void 0 : user.permissions.includes(permission);
    useEffect(() => {
        if (!isAllowed) {
            dispatch(showEventSnack({ isShowed: true, type: "err", messageValue: "У вас нет прав для доступа к странице" }));
        }
    }, []);
    if (!isAllowed) {
        return (React.createElement(Typography, { sx: { mt: "2rem" }, letterSpacing: 2, align: 'center', variant: 'h5', color: 'red' }, "C\u0442\u0440\u0430\u043D\u0438\u0446\u0430 \u043D\u0435\u0434\u043E\u0441\u0442\u0443\u043F\u043D\u0430"));
    }
    return children;
};
export { ProtectedComponentWithPermission };
