import React, { useState } from "react";
import { sendHttpReq } from "Src/utils";
import { apiHost } from "Src/variables";
import { Button, Input } from "@mui/material";
export const AddPosition = ({ department, onSave }) => {
    const [name, setName] = useState("");
    const save = () => {
        sendHttpReq({
            method: "post",
            url: apiHost + "/mrd/position",
            data: {
                departament_id: department.id,
                name: name,
            },
        }).then(({ data }) => {
            onSave(Object.assign({}, data));
            setName("");
        });
    };
    return React.createElement("div", { style: { paddingTop: "15px", paddingBottom: "15px" } },
        React.createElement(Input, { value: name, onChange: e => setName(e.target.value), style: { width: "300px" } }),
        React.createElement(Button, { onClick: save }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C"));
};
