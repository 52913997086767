import React, { useState } from "react";
import { IconButton, Input } from "@mui/material";
import { Cancel, Done, Edit } from "@mui/icons-material";
export const ChangeInput = ({ value, onSave }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState("");
    return React.createElement("span", null,
        !isEditing && (React.createElement("span", null,
            value,
            React.createElement(IconButton, { style: { marginLeft: "10px" }, onClick: () => {
                    setIsEditing(true);
                    setInputValue(value);
                } },
                React.createElement(Edit, null)))),
        isEditing && (React.createElement("span", null,
            React.createElement(Input, { value: inputValue, onChange: e => setInputValue(e.target.value) }),
            React.createElement(IconButton, { style: { marginLeft: "10px" }, onClick: () => {
                    setIsEditing(false);
                    setInputValue("");
                } },
                React.createElement(Cancel, null)),
            React.createElement(IconButton, { style: { marginLeft: "10px" }, onClick: () => {
                    onSave(inputValue);
                    setIsEditing(false);
                    setInputValue("");
                }, color: "success" },
                React.createElement(Done, null)))));
};
