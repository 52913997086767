import React, { useEffect, useState } from "react";
import { sendHttpReq } from "Src/utils";
import { Autocomplete, TextField } from "@mui/material";
export const ColorSelect = ({ currentColor, onChange }) => {
    const [colors, setColors] = useState([]);
    useEffect(() => {
        sendHttpReq({
            method: "get",
            url: "/colors",
        }).then(res => {
            setColors(res.data);
        });
    }, []);
    if (!colors.length) {
        return React.createElement(React.Fragment, null);
    }
    return React.createElement(Autocomplete, { value: currentColor || undefined, onChange: (e, value) => onChange(value), renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { variant: 'outlined', label: '\u0426\u0432\u0435\u0442' })), options: colors, getOptionLabel: (option) => (option === null || option === void 0 ? void 0 : option.name) || "(Не выбрано)", size: 'small', style: { width: "300px" }, isOptionEqualToValue: (option, value) => !!value && option.id === value.id });
};
