import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useAppDispatch } from "Redux/hooks";
import { showEventSnack } from "Redux/slices/appSlice";
import { sendHttpReq } from "Src/utils";
export const ExportSubPages = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [loadedData, setLoadedData] = useState(null);
    const openExcelDialog = () => {
        var input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", ".xls,.xlsx");
        input.onchange = (e) => {
            const input = e.currentTarget;
            let files = input.files;
            if (!files || !files.length) {
                dispatch(showEventSnack({ isShowed: true, messageValue: "Выберите файлы", type: "err" }));
                return;
            }
            let body = new FormData();
            body.append("file", files[0]);
            setIsLoading(true);
            sendHttpReq({
                method: "post",
                url: "/category-sub-page/import",
                data: body,
            }).then(({ data }) => {
                setLoadedData(data.loadedInfo);
            }).finally(() => {
                setIsLoading(false);
            }).catch(() => {
                setLoadedData(null);
            });
        };
        input.click();
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: 'contained', color: 'success', onClick: () => {
                openExcelDialog();
            }, disabled: isLoading, endIcon: isLoading ? React.createElement(CircularProgress, { value: 10, size: 20 }) : null }, "\u0417\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C Excel"),
        loadedData && React.createElement("div", { style: { verticalAlign: "middle" } },
            React.createElement("span", { style: { color: "green" } },
                "\u041E\u0431\u043D\u043E\u0432\u043B\u0435\u043D\u043E ",
                loadedData.length,
                " \u0441\u0442\u0440\u0430\u043D\u0438\u0446")));
};
