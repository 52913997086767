import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "Redux/hooks";
import { Tabs } from "@mui/material";
import { LinkTab } from "Src/components";
import { oldAdminUrl } from "Src/variables";
const menuLinks = [
    { href: `${oldAdminUrl}?section=Setup`, name: "параметры", permission: "adminSetup" },
    { href: `${oldAdminUrl}?section=Permissions`, name: "права", permission: "adminPermissions" },
    { href: `${oldAdminUrl}?section=Discounts`, name: "скидки", permission: "adminDiscountsSettings" },
    { href: `/settings/tabel`, name: "табель", permission: "tabelAdmin" },
];
const Settings = () => {
    const { pathname } = useLocation();
    const { user } = useAppSelector((state) => state.userInfo);
    const currentTabIndex = menuLinks.findIndex((menuLink) => pathname.startsWith(menuLink.href));
    return React.createElement("div", { style: { marginLeft: "20px", marginRight: "20px" } },
        React.createElement(Tabs, { sx: { mt: "10px", mb: "10px" }, value: currentTabIndex, role: 'navigation', scrollButtons: "auto", variant: "scrollable" }, menuLinks.map((menuLink) => ((user === null || user === void 0 ? void 0 : user.permissions.includes(menuLink.permission)) && (React.createElement(LinkTab, { key: menuLink.href, label: menuLink.name, to: menuLink.href }))))),
        React.createElement(Outlet, null));
};
export default Settings;
