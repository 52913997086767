import React, { useState } from "react";
import { useTitle } from "Src/hooks";
import { useAppDispatch } from "Redux/hooks";
import { sendHttpReq } from "Src/utils";
import { showEventSnack } from "Redux/slices/appSlice";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { CategorySubPageForm } from "Pages/Products/CategorySubPages/CategorySubPageForm/CategorySubPageForm";
const CategorySubPageAdd = () => {
    useTitle("Добавление страницы");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [categorySubPageEditing, setCategorySubPageEditing] = useState({
        id: "",
        url: "",
        fullUrl: "",
        title: "",
        sex: 0,
        size: "",
        season: "",
        category: {
            id: "1",
            name: "Одежда",
            url: "odezhda",
        },
        brand: null,
        color: null,
        material: null,
        metaTitle: "",
        metaKeywords: "",
        metaDescription: "",
        text: "",
        noindex: false,
        createdAt: "",
    });
    const add = (categorySubPageEditing) => {
        var _a, _b, _c, _d;
        sendHttpReq({
            method: "post",
            url: "/category-sub-page/add",
            data: {
                category_id: ((_a = categorySubPageEditing.category) === null || _a === void 0 ? void 0 : _a.id) || null,
                url: categorySubPageEditing.url,
                title: categorySubPageEditing.title,
                meta_title: categorySubPageEditing.metaTitle,
                meta_keywords: categorySubPageEditing.metaKeywords,
                meta_description: categorySubPageEditing.metaDescription,
                text: categorySubPageEditing.text,
                sex: categorySubPageEditing.sex,
                brand_id: ((_b = categorySubPageEditing.brand) === null || _b === void 0 ? void 0 : _b.id) || null,
                color_id: ((_c = categorySubPageEditing.color) === null || _c === void 0 ? void 0 : _c.id) || null,
                material_id: ((_d = categorySubPageEditing.material) === null || _d === void 0 ? void 0 : _d.id) || null,
                season: categorySubPageEditing.season,
                size: categorySubPageEditing.size,
                noindex: categorySubPageEditing.noindex,
            },
        }).then(res => {
            setCategorySubPageEditing(Object.assign({}, res.data));
            dispatch(showEventSnack({ isShowed: true, type: "success", messageValue: "Сохранено" }));
            navigate("/products/categorySubPages/edit/" + res.data.id);
        });
    };
    return React.createElement("div", null,
        React.createElement("div", { style: { marginBottom: "10px" } },
            React.createElement(Link, { to: "/products/categorySubPages" },
                React.createElement(Button, { size: 'small' }, "\u041A \u0441\u043F\u0438\u0441\u043A\u0443 \u0441\u0442\u0440\u0430\u043D\u0438\u0446"))),
        React.createElement(CategorySubPageForm, { categorySubPage: categorySubPageEditing, onChange: (categorySubPageFull) => setCategorySubPageEditing(categorySubPageFull), onSubmit: (categorySubPageEditing) => add(categorySubPageEditing) }));
};
export default CategorySubPageAdd;
