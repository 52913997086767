import "./ErrorBoundary.scss";
import React from "react";
import store from "Redux/store";
import { withScope, captureException } from "@sentry/browser";
import { sendHttpReq } from "Utils/index";
class ErrorBoundary extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { isLayoutShown: false, chunkError: false };
    }
    static getDerivedStateFromError(error) {
        const chunkErrorArr = error.message
            ? error.message.match(/Loading chunk/gi) || error.message.match(/Loading CSS chunk/gi)
            : [];
        if (chunkErrorArr && chunkErrorArr.length > 0) {
            if (window["chunkErrorReload"])
                return { isLayoutShown: false, chunkError: true };
            const lastErrorTime = parseInt(localStorage.getItem("chunkErrorTime") || "0");
            const currentTime = new Date().getTime();
            if (currentTime - lastErrorTime > 60000) {
                window["chunkErrorReload"] = true;
                localStorage.setItem("chunkErrorTime", "" + currentTime);
                window.location.reload();
                return { isLayoutShown: false, chunkError: true };
            }
        }
        return { isLayoutShown: true, chunkError: chunkErrorArr && chunkErrorArr.length > 0 };
    }
    componentDidCatch(error, trace) {
        var _a;
        withScope((scope) => {
            var _a;
            scope.setExtras({
                trace,
                localStorage,
                reduxState: store.getState(),
                PerformanceEntry: ((_a = window.performance) === null || _a === void 0 ? void 0 : _a.getEntries()) ? JSON.stringify(performance.getEntries()) : "not supported",
            });
            captureException(error);
        });
        if (location.origin.includes("ls.net.ru") && this.state.isLayoutShown) {
            sendHttpReq({
                method: "post",
                url: "/log-error",
                data: {
                    errorText: error.toString(),
                    url: location.href,
                    navigantor: {
                        appVersion: navigator === null || navigator === void 0 ? void 0 : navigator.appVersion,
                        platform: navigator === null || navigator === void 0 ? void 0 : navigator.platform,
                        userAgent: navigator === null || navigator === void 0 ? void 0 : navigator.userAgent,
                        userAgentData: navigator === null || navigator === void 0 ? void 0 : navigator.userAgentData,
                        vendor: navigator === null || navigator === void 0 ? void 0 : navigator.vendor,
                    },
                    localStorage,
                    reduxState: store.getState(),
                    PerformanceEntry: ((_a = window.performance) === null || _a === void 0 ? void 0 : _a.getEntries())
                        ? JSON.stringify(performance.getEntries())
                        : "not supported",
                    trace: JSON.stringify(trace),
                },
            });
        }
    }
    render() {
        const { chunkError, isLayoutShown } = this.state;
        if (isLayoutShown) {
            return (React.createElement("div", { className: 'errorBoundary__wrapper' },
                React.createElement("img", { src: 'https://cdn.ls.net.ru/images/error.png', alt: 'Sorry, cliet-server error' }),
                React.createElement("p", null, chunkError ? "Упс! Похоже интернет соединение прервалось." : "Упс! Похоже у нас что-то поломалось."),
                React.createElement("span", null, chunkError ? "Попробуйте перезагрузить страницу." : "Попробуйте вернуться на нашу главную страницу."),
                !chunkError && (React.createElement("a", { href: '/' },
                    React.createElement("button", null, "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043D\u0430 \u0433\u043B\u0430\u0432\u043D\u0443\u044E")))));
        }
        return this.props.children;
    }
}
export { ErrorBoundary };
