import React from "react";
import { Button, TextField } from "@mui/material";
import { CategorySelect } from "Pages/Products/CategorySubPages/CategorySubPageForm/CategorySelect";
import { UrlInput } from "Pages/Products/CategorySubPages/CategorySubPageForm/UrlInput";
import { SexInput } from "Pages/Products/CategorySubPages/CategorySubPageForm/SexInput";
import { BrandSelect } from "Pages/Products/CategorySubPages/CategorySubPageForm/BrandSelect";
import { ColorSelect } from "Pages/Products/CategorySubPages/CategorySubPageForm/ColorSelect";
import { MaterialSelect } from "Pages/Products/CategorySubPages/CategorySubPageForm/MaterialSelect";
export const CategorySubPageForm = ({ categorySubPage, onChange, onSubmit }) => {
    const setField = (key, value) => {
        const result = Object.assign({}, categorySubPage);
        result[key] = value;
        onChange(result);
    };
    return React.createElement("form", { onSubmit: (e) => {
            e.preventDefault();
            onSubmit(categorySubPage);
        } },
        React.createElement(TextField, { value: categorySubPage.title, onChange: (e) => setField("title", e.target.value), margin: 'dense', id: 'title', label: '\u0417\u0430\u0433\u043E\u043B\u043E\u0432\u043E\u043A', fullWidth: true, variant: 'outlined', size: 'small', required: true, error: !categorySubPage.title.trim(), helperText: !categorySubPage.title.trim() ? "Укажите заголовок" : "" }),
        React.createElement("div", { style: { marginTop: "10px", display: "flex", gap: "15px" } },
            React.createElement(CategorySelect, { currentCategory: categorySubPage.category, onChange: (category) => setField("category", category) }),
            React.createElement(UrlInput, { currentCategory: categorySubPage.category, url: categorySubPage.url, onChange: (url) => setField("url", url), currentPageId: categorySubPage.id })),
        React.createElement("div", { style: { margin: "20px 0" } },
            React.createElement("h2", { style: { marginBottom: "10px" } }, "\u0424\u0438\u043B\u044C\u0442\u0440\u044B:"),
            React.createElement("div", { style: { display: "flex", flexWrap: "wrap", gap: "15px" } },
                React.createElement(SexInput, { sex: categorySubPage.sex, onChange: (sex) => setField("sex", sex) }),
                React.createElement(BrandSelect, { currentBrand: categorySubPage.brand, onChange: (brand) => setField("brand", brand) }),
                React.createElement(ColorSelect, { currentColor: categorySubPage.color, onChange: color => setField("color", color) }),
                React.createElement(MaterialSelect, { currentMaterial: categorySubPage.material, onChange: material => setField("material", material) }),
                React.createElement(TextField, { value: categorySubPage.season, onChange: (e) => setField("season", e.target.value), id: 'season', label: '\u0421\u0435\u0437\u043E\u043D', variant: 'outlined', size: 'small' }),
                React.createElement(TextField, { value: categorySubPage.size, onChange: (e) => setField("size", e.target.value), id: 'size', label: '\u0420\u0430\u0437\u043C\u0435\u0440', variant: 'outlined', size: 'small' }))),
        React.createElement("div", null,
            React.createElement("h2", { style: { marginBottom: "7px" } }, "SEO:"),
            React.createElement("label", { style: { marginBottom: "7px", display: "inline-block" } },
                React.createElement("input", { type: "checkbox", checked: categorySubPage.noindex, onChange: (e) => setField("noindex", e.target.checked) }),
                "\u041D\u0435 \u0438\u043D\u0434\u0435\u043A\u0441\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0443"),
            React.createElement(TextField, { value: categorySubPage.metaTitle, onChange: (e) => setField("metaTitle", e.target.value), margin: 'dense', id: 'metaTitle', label: 'Meta Title', fullWidth: true, variant: 'outlined', size: 'small' }),
            React.createElement(TextField, { value: categorySubPage.metaKeywords, onChange: (e) => setField("metaKeywords", e.target.value), margin: 'dense', id: 'metaKeywords', label: 'Meta Keywords', fullWidth: true, variant: 'outlined', size: 'small' }),
            React.createElement(TextField, { value: categorySubPage.metaDescription, onChange: (e) => setField("metaDescription", e.target.value), margin: 'dense', id: 'metaDescription', label: 'Meta Description', fullWidth: true, variant: 'outlined', size: 'small' }),
            React.createElement(TextField, { value: categorySubPage.text, onChange: (e) => setField("text", e.target.value), margin: 'dense', id: 'text', label: '\u0422\u0435\u043A\u0441\u0442', fullWidth: true, variant: 'outlined', size: 'small', multiline: true, rows: 7 })),
        React.createElement(Button, { variant: 'contained', color: 'success', style: { marginTop: "30px", marginBottom: "15px" }, type: 'submit' }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"));
};
