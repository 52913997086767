import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { oldAdminUrl } from "Src/variables";
import { LinkTab } from "Src/components";
import { Tabs } from "@mui/material";
import { useAppSelector } from "Redux/hooks";
const menuLinks = [
    { href: `${oldAdminUrl}?section=Storefront`, name: "товары", permission: "adminStorefront" },
    { href: `${oldAdminUrl}?section=ProductPreviews`, name: "Предварительные товары", permission: "productPreviews" },
    { href: `${oldAdminUrl}?section=Categories`, name: "категории", permission: "adminCategories" },
    { href: `${oldAdminUrl}?section=Brands`, name: "бренды", permission: "adminBrands" },
    { href: `${oldAdminUrl}?section=Goods`, name: "бренд-категория", permission: "adminGoods" },
    { href: `${oldAdminUrl}?section=Materials`, name: "материалы", permission: "adminMaterials" },
    { href: `${oldAdminUrl}?section=Sets`, name: "наборы", permission: "adminSets" },
    { href: `${oldAdminUrl}?section=Banners`, name: "баннеры", permission: "adminBanners" },
    { href: `${oldAdminUrl}?section=SaleBanner`, name: "Баннер sale", permission: "saleBanner" },
    { href: `${oldAdminUrl}?section=Shops`, name: "магазины", permission: "adminShops" },
    { href: `/products/categorySubPages`, name: "Подстраницы категорий", permission: "categorySubPages" },
];
const Products = () => {
    const { pathname } = useLocation();
    const { user } = useAppSelector((state) => state.userInfo);
    const currentTabIndex = menuLinks.findIndex((menuLink) => pathname.startsWith(menuLink.href));
    return React.createElement("div", { style: { marginLeft: "20px", marginRight: "20px" } },
        React.createElement(Tabs, { sx: { mt: "10px", mb: "10px" }, value: currentTabIndex, role: 'navigation', scrollButtons: "auto", variant: "scrollable" }, menuLinks.map((menuLink) => ((user === null || user === void 0 ? void 0 : user.permissions.includes(menuLink.permission)) && (React.createElement(LinkTab, { key: menuLink.href, label: menuLink.name, to: menuLink.href }))))),
        React.createElement(Outlet, null));
};
export default Products;
