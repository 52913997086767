import React, { useEffect, useState } from "react";
import { sendHttpReq } from "Src/utils";
import { apiHost } from "Src/variables";
import { Button, FormControl } from "@mui/material";
import Select from "react-select";
export const FillDepartmentData = ({ department, users }) => {
    const [headUsers, setHeadUsers] = useState([]);
    const [responsibleUsers, setResponsibleUsers] = useState([]);
    useEffect(() => {
        sendHttpReq({
            method: "get",
            url: apiHost + "/mrd/department-heads?departmentId=" + department.id,
        }).then(({ data }) => {
            setHeadUsers(data.headUsers);
            setResponsibleUsers(data.responsibleUsers);
        });
    }, []);
    const save = () => {
        sendHttpReq({
            method: "post",
            url: apiHost + "/mrd/department-heads/save",
            data: {
                departmentId: department.id,
                headUsers: headUsers.map(user => ({ userId: user.id })),
                responsibleUsers: responsibleUsers.map(user => ({ userId: user.id })),
            },
        }).then(({ data }) => {
            setHeadUsers(data.headUsers);
            setResponsibleUsers(data.responsibleUsers);
        });
    };
    return React.createElement("div", { style: { marginTop: "20px" } },
        React.createElement("h2", null, "\u0420\u0443\u043A\u043E\u0432\u043E\u0434\u0438\u0442\u0435\u043B\u0438:"),
        React.createElement(FormControl, { style: { marginBottom: "30px" }, fullWidth: true },
            React.createElement(Select, { options: users, getOptionLabel: (user) => `${user.name} (${user.id})`, getOptionValue: (user) => user.id, isMulti: true, value: headUsers, onChange: (value) => setHeadUsers(value.map(({ id, name }) => ({ id, name }))) })),
        React.createElement("h2", null, "\u041E\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0435\u043D\u043D\u044B\u0435:"),
        React.createElement(FormControl, { style: { marginBottom: "30px" }, fullWidth: true },
            React.createElement(Select, { options: users, getOptionLabel: (user) => `${user.name} (${user.id})`, getOptionValue: (user) => user.id, isMulti: true, value: responsibleUsers, onChange: (value) => setResponsibleUsers(value.map(({ id, name }) => ({ id, name }))) })),
        React.createElement(Button, { color: 'success', variant: 'contained', onClick: () => save() }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"));
};
