import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
export const SexInput = ({ sex, onChange }) => {
    return React.createElement(FormControl, { variant: 'outlined', size: 'small' },
        React.createElement(InputLabel, null, "\u041F\u043E\u043B"),
        React.createElement(Select, { id: 'sex', name: 'sex', value: sex, label: '\u041F\u043E\u043B', size: 'small', onChange: (e) => onChange(parseInt(e.target.value)) },
            React.createElement(MenuItem, { value: 0 }, "\u041B\u044E\u0431\u043E\u0439"),
            React.createElement(MenuItem, { value: 1 }, "\u041C\u0443\u0436\u0441\u043A\u043E\u0439"),
            React.createElement(MenuItem, { value: 2 }, "\u0416\u0435\u043D\u0441\u043A\u0438\u0439")));
};
