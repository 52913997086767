import React, { useState } from "react";
import { Button, IconButton } from "@mui/material";
import { ChangeInput } from "Pages/Settings/Tabel/Companies/Components/ChangeInput";
import { Delete } from "@mui/icons-material";
import { sendHttpReq } from "Src/utils";
import { apiHost } from "Src/variables";
import { AddPosition } from "Pages/Settings/Tabel/Companies/Components/AddPosition";
export const DepartmentPositions = ({ department, onChange }) => {
    const [showPositions, setShowPositions] = useState(false);
    const renamePosition = (position, newName) => {
        sendHttpReq({
            method: "put",
            url: apiHost + "/mrd/position/" + position.id,
            data: {
                name: newName,
            },
        }).then(() => {
            onChange();
        });
    };
    const removePosition = (position) => {
        if (!confirm(`Удалить должность ${position.name}?`)) {
            return;
        }
        sendHttpReq({
            method: "delete",
            url: apiHost + "/mrd/position/" + position.id,
        }).then(() => {
            onChange();
        });
    };
    return React.createElement("div", null,
        React.createElement(Button, { onClick: () => setShowPositions(!showPositions) },
            showPositions ? "Скрыть" : "Показать",
            " \u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u0438"),
        showPositions && (React.createElement("div", null,
            department.positions.map(position => (React.createElement("div", { key: position.id, style: { lineHeight: 2, fontSize: "16px", padding: "15px", border: "1px #ccc solid", marginBottom: "7px" } },
                React.createElement("div", null,
                    React.createElement(ChangeInput, { value: position.name, onSave: value => renamePosition(position, value) }),
                    React.createElement(IconButton, { style: { marginLeft: "10px" }, color: 'error', onClick: () => removePosition(position) },
                        React.createElement(Delete, null)))))),
            React.createElement(AddPosition, { department: department, onSave: onChange }))));
};
