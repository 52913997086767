import { useEffect, useState } from "react";
import { crmApiUrl } from "Src/variables";
import { sendHttpReq } from "Utils/sendHttpReq";
import { isInvalidDate } from "Utils/isInvalidDate";
import { useAppDispatch } from "Redux/hooks";
import { showEventSnack } from "Redux/slices/appSlice";
import { usePagination, useQuery } from "Src/hooks";
import { temporaryToken } from "Src/constants";
const useDelivery = ({ dateFrom, dateTo, dateErrFrom, dateErrTo }) => {
    const dispatch = useAppDispatch();
    const query = useQuery();
    const { currentPage, setCurrentPage, onCurrentPageChange } = usePagination();
    const [isLoading, setIsLoading] = useState(false);
    const [deliveries, setDeliveries] = useState([]);
    const [pagesCount, setPagesCount] = useState(1);
    const [refresh, setRefresh] = useState(false);
    const refetch = () => setRefresh(!refresh);
    useEffect(() => {
        getDeliveries();
    }, [currentPage, refresh]);
    const getDeliveries = (resetPage) => {
        if (isInvalidDate(dateFrom, dateTo, dateErrFrom, dateErrTo))
            return;
        setIsLoading(true);
        setDeliveries([]);
        resetPage && setCurrentPage(1);
        const parsedDateFrom = new Date(Date.parse(dateFrom.toString()) + 10800000).toISOString();
        const parsedDateTo = new Date(Date.parse(dateTo.toString()) + 97199999).toISOString();
        const filters = {
            dateFrom: parsedDateFrom,
            dateTo: parsedDateTo,
            page: `${resetPage ? 1 : currentPage}`,
            search: query.get("search") || "",
        };
        sendHttpReq({
            method: "get",
            url: `${crmApiUrl}/courier-deliveries?` + new URLSearchParams(filters).toString(),
            authHeader: temporaryToken,
        })
            .then((res) => {
            setDeliveries(res.data.data);
            setPagesCount(res.data.pagesCount);
            setIsLoading(false);
        })
            .catch((err) => {
            dispatch(showEventSnack({ isShowed: true, messageValue: err, type: "err" }));
            setIsLoading(false);
        });
    };
    const onUpdate = (shouldRefetch = true, delivery) => {
        if (!shouldRefetch && delivery) {
            setDeliveries((prevDeliveries) => prevDeliveries.map((prevDelivery) => {
                if (prevDelivery.id === delivery.id) {
                    return delivery;
                }
                return prevDelivery;
            }));
            return;
        }
        refetch();
    };
    const onDelete = (delivery) => {
        setDeliveries((prevDeliveries) => prevDeliveries.filter((prevDelivery) => prevDelivery.id !== delivery.id));
    };
    return {
        deliveries,
        getDeliveries,
        isLoading,
        pagesCount,
        currentPage,
        onCurrentPageChange,
        onUpdate,
        onDelete,
    };
};
export { useDelivery };
