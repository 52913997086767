import './categorySubPage.scss';
import React, { useEffect, useState } from "react";
import { useTitle } from "Src/hooks";
import { sendHttpReq } from "Src/utils";
import { Button, Pagination } from "@mui/material";
import { CategorySubPagesList } from "Pages/Products/CategorySubPages/CategorySubPagesList";
import { Link } from "react-router-dom";
import { useAppDispatch } from "Redux/hooks";
import { showEventSnack } from "Redux/slices/appSlice";
import { CategorySubPageFilters } from "Pages/Products/CategorySubPages/CategorySubPageFilters";
import { apiUrl } from "Src/variables";
import { ExportSubPages } from "Pages/Products/CategorySubPages/ExportSubPages";
const CategorySubPages = () => {
    useTitle("Подстраницы категорий");
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [pagesCount, setPagesCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [categorySubPages, setCategorySubPages] = useState([]);
    const [filters, setFilters] = useState({
        url: "",
        categories: [],
        sex: 0,
    });
    const loadList = () => {
        setIsLoading(true);
        const searchFilters = {
            url: filters.url.trim(),
            categoryIds: filters.categories.map(i => i.id).join(),
            sex: "" + filters.sex,
        };
        const searchParams = Object.assign({ page: "" + currentPage }, searchFilters);
        sendHttpReq({
            method: "get",
            url: "/category-sub-pages?" + new URLSearchParams(searchParams).toString(),
        }).then(res => {
            setCategorySubPages(res.data.data);
            setPagesCount(res.data.pagesCount);
            setIsLoading(false);
        });
    };
    const remove = (categorySubPage) => {
        if (!confirm(`Удалить страницу ${categorySubPage.title}?`)) {
            return;
        }
        sendHttpReq({
            method: "delete",
            url: "/category-sub-page/" + categorySubPage.id,
        }).then(() => {
            dispatch(showEventSnack({ isShowed: true, type: "success", messageValue: "Страница удалена" }));
            const newPages = [...categorySubPages];
            const index = newPages.findIndex(i => i.id === categorySubPage.id);
            if (index >= 0)
                newPages.splice(index, 1);
            setCategorySubPages(newPages);
        });
    };
    useEffect(() => {
        loadList();
    }, [currentPage, filters]);
    const [downloadParams, setDownloadParams] = useState("");
    useEffect(() => {
        let searchParams = {
            url: filters.url.trim(),
            categoryIds: filters.categories.map(i => i.id).join(),
            sex: "" + filters.sex,
        };
        setDownloadParams(new URLSearchParams(searchParams).toString());
    }, [filters]);
    return React.createElement("div", null,
        React.createElement("div", { style: { margin: "0 15px" } },
            React.createElement(Link, { to: '/products/categorySubPages/add' },
                React.createElement(Button, { variant: 'contained' }, "\u0421\u043E\u0437\u0434\u0430\u0442\u044C")),
            React.createElement("div", { style: { marginTop: "20px" } },
                React.createElement(CategorySubPageFilters, { updateFilters: setFilters, subButtons: React.createElement(React.Fragment, null,
                        React.createElement("a", { href: apiUrl + "category-sub-page/export?" + downloadParams },
                            React.createElement(Button, { color: 'primary', variant: 'contained' }, "\u0421\u043A\u0430\u0447\u0430\u0442\u044C")),
                        React.createElement(ExportSubPages, null)) }))),
        !isLoading && pagesCount > 1 && (React.createElement(Pagination, { className: 'customPagination_teal customPagination_top', sx: { mt: "1rem" }, showFirstButton: true, showLastButton: true, page: currentPage, count: pagesCount, onChange: (e, page) => setCurrentPage(page) })),
        React.createElement("div", { style: { marginTop: "15px" } },
            React.createElement(CategorySubPagesList, { categorySubPages: categorySubPages, isLoading: isLoading, remove: (categorySubPage) => remove(categorySubPage) })),
        !isLoading && pagesCount > 1 && (React.createElement(Pagination, { className: 'customPagination_teal customPagination_bottom', sx: { mt: "1rem" }, showFirstButton: true, showLastButton: true, page: currentPage, count: pagesCount, onChange: (e, page) => setCurrentPage(page) })));
};
export default CategorySubPages;
