import React, { useState } from "react";
import { Button, IconButton } from "@mui/material";
import { ChangeInput } from "Pages/Settings/Tabel/Companies/Components/ChangeInput";
import { sendHttpReq } from "Src/utils";
import { apiHost } from "Src/variables";
import { Delete } from "@mui/icons-material";
import { AddDepartment } from "Pages/Settings/Tabel/Companies/Components/AddDepartment";
import { DepartmentPositions } from "Pages/Settings/Tabel/Companies/DepartmentPositions";
export const CompanyDepartments = ({ company, onChange }) => {
    const [showDepartments, setShowDepartments] = useState(false);
    const renameDepartment = (department, newName) => {
        sendHttpReq({
            method: "put",
            url: apiHost + "/mrd/departament/" + department.id,
            data: {
                name: newName,
            },
        }).then(() => {
            onChange();
        });
    };
    const removeDepartment = (department) => {
        if (!confirm(`Удалить отдел ${department.name}?`)) {
            return;
        }
        sendHttpReq({
            method: "delete",
            url: apiHost + "/mrd/departament/" + department.id,
        }).then(() => {
            onChange();
        });
    };
    return React.createElement("div", null,
        React.createElement(Button, { onClick: () => setShowDepartments(!showDepartments) },
            showDepartments ? "Скрыть" : "Показать",
            " \u043E\u0442\u0434\u0435\u043B\u044B"),
        showDepartments && (React.createElement("div", null,
            company.departments.map(department => (React.createElement("div", { key: department.id, style: { lineHeight: 2, fontSize: "16px", padding: "15px", border: "1px #ccc solid", marginBottom: "7px" } },
                React.createElement("div", null,
                    React.createElement(ChangeInput, { value: department.name, onSave: value => renameDepartment(department, value) }),
                    !department.positions.length && (React.createElement(IconButton, { style: { marginLeft: "10px" }, color: 'error', onClick: () => removeDepartment(department) },
                        React.createElement(Delete, null)))),
                React.createElement(DepartmentPositions, { department: department, onChange: onChange })))),
            React.createElement(AddDepartment, { company: company, onSave: onChange }))));
};
