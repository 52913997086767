import React, { useEffect, useState } from "react";
import { sendHttpReq } from "Src/utils";
import { Autocomplete, TextField } from "@mui/material";
export const BrandSelect = ({ currentBrand, onChange }) => {
    const [brands, setBrands] = useState([]);
    useEffect(() => {
        sendHttpReq({
            method: "get",
            url: "/brands/available",
        }).then(res => {
            setBrands(res.data);
        });
    }, []);
    if (!brands.length) {
        return React.createElement(React.Fragment, null);
    }
    return React.createElement(Autocomplete, { value: currentBrand || undefined, onChange: (e, value) => onChange(value), renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { variant: 'outlined', label: '\u0411\u0440\u0435\u043D\u0434' })), options: brands, getOptionLabel: (option) => (option === null || option === void 0 ? void 0 : option.name) || "(Не выбрано)", size: 'small', style: { width: "300px" }, isOptionEqualToValue: (option, value) => !!value && option.id === value.id });
};
