import { Close, Done } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { BasicTableRow } from "Components/BasicTableRow";
import { useAppDispatch } from "Redux/hooks";
import { showEventSnack } from "Redux/slices/appSlice";
import { sendHttpReq } from "Utils/sendHttpReq";
import React, { useState } from "react";
import { MarketingLoyaltyAdditionalDiscountsDialogForm } from "../MarketingLoyaltyAdditionalDiscountsDialogForm";
import { allClientsValue } from "../MarketingLoyaltyAdditionalDiscountsDialogForm/MarketingLoyaltyAdditionalDiscountsDialogForm.data";
const MarketingLoyaltyAdditionalDiscountsTableRow = ({ item, onEditAdditionalDiscount, onDeleteAdditionalDiscount, }) => {
    const dispatch = useAppDispatch();
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
    const onAddDialogOpen = () => setIsAddDialogOpen(true);
    const onAddDialogClose = () => setIsAddDialogOpen(false);
    const { date_from: dateFrom, date_to: dateTo, enabled, increase_percent: increasePercent, id, only_without_orders: onlyWithoutOrders, only_without_returnable_orders: onlyWithoutReturnableOrders, users_filters, } = item;
    const renderCheckBoxCell = (checked) => {
        if (checked) {
            return React.createElement(Done, { color: 'success' });
        }
        return React.createElement(Close, { color: 'error' });
    };
    const [isDeleting, setIsDeleting] = useState(false);
    const onDelete = () => {
        if (!confirm("Вы уверены?"))
            return;
        setIsDeleting(true);
        sendHttpReq({ method: "delete", url: `loyalty-additional-discount/${id}` })
            .then(() => {
            onDeleteAdditionalDiscount(id);
            dispatch(showEventSnack({ isShowed: true, messageValue: "Доп скидка успешно удалена", type: "success" }));
        })
            .catch(() => { })
            .finally(() => setIsDeleting(false));
    };
    const cells = [
        dateFrom,
        dateTo,
        increasePercent,
        renderCheckBoxCell(!!onlyWithoutOrders),
        renderCheckBoxCell(!!onlyWithoutReturnableOrders),
        renderCheckBoxCell(!!enabled),
        users_filters === null || users_filters === void 0 ? void 0 : users_filters.ids.join(","),
        (users_filters === null || users_filters === void 0 ? void 0 : users_filters.statuses.join(",")) || allClientsValue,
        React.createElement("div", { style: { display: "flex", flexDirection: "column", gap: "0.8rem" } },
            React.createElement(Button, { variant: 'contained', onClick: onAddDialogOpen }, "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C"),
            React.createElement(LoadingButton, { loading: isDeleting, onClick: onDelete, color: 'error', variant: 'contained' }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C")),
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(BasicTableRow, { cells: cells }),
        React.createElement(MarketingLoyaltyAdditionalDiscountsDialogForm, { isAddDialogOpen: isAddDialogOpen, onSuccess: onEditAdditionalDiscount, isEdit: true, onAddDialogClose: onAddDialogClose, id: id, item: item })));
};
export { MarketingLoyaltyAdditionalDiscountsTableRow };
