import React, { useEffect, useState } from "react";
import { sendHttpReq } from "Src/utils";
import { apiHost } from "Src/variables";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FillDepartmentData } from "Pages/Settings/Tabel/DepartmentHeads/FillDepartmentData";
export const DepartmentHeads = () => {
    const [companies, setCompanies] = useState([]);
    const [currentCompany, setCurrentCompany] = useState(null);
    const [currentDepartment, setCurrentDepartment] = useState(null);
    const [users, setUsers] = useState([]);
    useEffect(() => {
        sendHttpReq({
            method: "get",
            url: apiHost + "/mrd/company",
        }).then(({ data }) => {
            var _a;
            setCompanies(data);
            setCurrentCompany(data[0] || null);
            setCurrentDepartment(((_a = data[0]) === null || _a === void 0 ? void 0 : _a.departments[0]) || null);
        });
        sendHttpReq({
            method: "get",
            url: apiHost + "/mrd/users",
        }).then(({ data }) => {
            setUsers(data);
        });
    }, []);
    if (!companies.length || !currentCompany || !currentDepartment) {
        return React.createElement(React.Fragment, null);
    }
    return React.createElement("div", null,
        React.createElement(FormControl, { variant: 'outlined', style: { width: "300px" } },
            React.createElement(InputLabel, { id: 'company' }, "\u041A\u043E\u043C\u043F\u0430\u043D\u0438\u044F"),
            React.createElement(Select, { labelId: 'company', name: 'company', value: (currentCompany === null || currentCompany === void 0 ? void 0 : currentCompany.id) || null, label: '\u041A\u043E\u043C\u043F\u0430\u043D\u0438\u044F' }, companies.map(company => (React.createElement(MenuItem, { key: company.id, value: company.id, onClick: () => {
                    setCurrentCompany(company);
                    setCurrentDepartment(company.departments[0] || null);
                } }, company.name))))),
        React.createElement(FormControl, { variant: 'outlined', style: { width: "300px", marginLeft: "10px" } },
            React.createElement(InputLabel, { id: 'department' }, "\u041E\u0442\u0434\u0435\u043B"),
            React.createElement(Select, { labelId: 'department', name: 'department', value: (currentDepartment === null || currentDepartment === void 0 ? void 0 : currentDepartment.id) || null, label: '\u041E\u0442\u0434\u0435\u043B' }, currentCompany.departments.map(department => (React.createElement(MenuItem, { key: department.id, value: department.id, onClick: () => setCurrentDepartment(department) }, department.name))))),
        React.createElement("div", null,
            React.createElement(FillDepartmentData, { department: currentDepartment, users: users, key: currentDepartment.id })));
};
