import React, { useEffect, useState } from "react";
import { sendHttpReq } from "Src/utils";
import { Autocomplete, TextField } from "@mui/material";
export const MaterialSelect = ({ currentMaterial, onChange }) => {
    const [materials, setMaterials] = useState([]);
    useEffect(() => {
        sendHttpReq({
            method: "get",
            url: "/materials",
        }).then(res => {
            setMaterials(res.data);
        });
    }, []);
    if (!materials.length) {
        return React.createElement(React.Fragment, null);
    }
    return React.createElement(Autocomplete, { value: currentMaterial || undefined, onChange: (e, value) => onChange(value), renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { variant: 'outlined', label: '\u041C\u0430\u0442\u0435\u0440\u0438\u0430\u043B' })), options: materials, getOptionLabel: (option) => (option === null || option === void 0 ? void 0 : option.name) || "(Не выбрано)", size: 'small', style: { width: "300px" }, isOptionEqualToValue: (option, value) => !!value && option.id === value.id });
};
