import React, { useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";
import { sendHttpReq } from "Src/utils";
import { siteUrl } from "Src/variables";
export const UrlInput = ({ url, onChange, currentCategory, currentPageId }) => {
    const inputRef = useRef();
    const [error, setError] = useState("");
    useEffect(() => {
        if (!inputRef.current) {
            return;
        }
        inputRef.current.setCustomValidity(error);
    }, [error]);
    useEffect(() => {
        if (!currentCategory) {
            return;
        }
        if (url === "") {
            setError("Укажите URL");
            return;
        }
        if (!url.match(/^[a-z0-9-]+$/)) {
            setError("URL может содержать только строчные латинские буквы, цифры, тире");
            return;
        }
        sendHttpReq({
            method: "post",
            url: "/category-sub-page/check-url",
            data: {
                url: url,
                categoryId: currentCategory.id,
                currentPageId: currentPageId,
            },
        }).then(res => {
            if (res.data.exists) {
                setError("URL занят!");
            }
            else {
                setError("");
            }
        });
    }, [currentCategory, url]);
    if (!currentCategory) {
        return React.createElement(React.Fragment, null);
    }
    return React.createElement("div", { style: { display: "flex", gap: "10px", alignItems: "center" } },
        React.createElement("span", { style: { flexShrink: 0 } },
            siteUrl,
            "/tags/"),
        React.createElement(TextField, { value: url, onChange: (e) => onChange(e.target.value), margin: 'dense', id: 'url', label: 'URL', fullWidth: true, variant: 'outlined', size: 'small', required: true, error: !!error, helperText: error, inputRef: inputRef }));
};
